import { forwardRef } from 'react';

interface LogoProps {
  className?: string;
  style?: React.CSSProperties;
  useGradient?: boolean;
}

const Logo = forwardRef<SVGSVGElement, LogoProps>(({ useGradient = true, ...props }, ref) => {
  return (
    <svg 
      ref={ref}
      width="30"
      height="30"
      viewBox="0 0 486 300"
      {...props}
    >
      {useGradient && (
        <defs>
          <linearGradient id="logoGradient">
            <stop stopColor="#003fff" offset="0" />
            <stop stopColor="#0ec0ff" offset="0.50287" />
            <stop stopColor="#00f6ff" offset="1" />
          </linearGradient>
        </defs>
      )}
      <g transform="translate(0,3)">
        <path
          transform="matrix(.26458 0 0 .26458 0 -3)"
          d="m778.98 0l390.39 676.17h403.24l-390.39-676.17h-403.24zm528.49 0l264.69 458.46 264.69-458.46h-529.38zm-653.05 0.37695l-654.42 1133.5h403.43l452.7-784.1-201.71-349.38zm264.1 457.44l-126.06 218.35h0.22851l-0.02734 0.04883h251.54l-0.0273-0.04883h0.416l-126.06-218.35zm-188.46 326.41l-201.86 349.62h1308.6l-201.86-349.62h-904.93z"
          fill={useGradient ? "url(#logoGradient)" : "#000000"}
        />
      </g>
    </svg>
  );
});

Logo.displayName = 'Logo';

export default Logo;