import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import type { Page } from '../App';
import Logo from './Logo';
import '../styles/navbar.css';
import WebsiteCard from './websiteCard';
import GitHubLogo from './GitHubLogo';
import gitlabIcon from '../assets/svg/external_pages/gitlab.svg';
import linkedinIcon from '../assets/svg/external_pages/LinkedIn.svg';
import GoogleScholarLogo from './GoogleScholarLogo';

interface NavTopBarProps {
  myName: string;
  pages: Record<string, Page>;
}

const websites = [
  {
    title: 'GitHub',
    link: 'https://github.com/AlxSp',
    component: <GitHubLogo useCurrentColor={true} width="24" height="24" />
  },
  {
    title: 'GitLab',
    link: 'https://gitlab.com/AlexSpr',
    imageSrc: gitlabIcon
  },
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/alexander-speicher/',
    imageSrc: linkedinIcon
  },
  {
    title: 'Google Scholar',
    link: 'https://scholar.google.com/citations?user=6yGciHoAAAAJ&hl=en',
    component: <GoogleScholarLogo useCurrentColor={true} width="24" height="24" />
  }
];

const NavTopBar = ({ myName, pages }: NavTopBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);
  return (
    <nav className="topNavBar">
      <div className="navbar-container">
        <NavLink to="/" className="brand" onClick={handleClose}>
          <Logo useGradient={false} />
          {myName}
        </NavLink>
        <button 
          className={`menu-button ${isOpen ? 'open' : ''}`}
          onClick={handleToggle}
          aria-label="Toggle navigation"
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          {Object.values(pages).map((page) => (
            <NavLink
              key={page.link}
              to={page.link}
              onClick={handleClose}
              className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
            >
              {page.title}
            </NavLink>
          ))}
          {websites.map((site) => (
            <WebsiteCard 
              key={site.link}
              title={site.title}
              link={site.link}
              imageSrc={site.imageSrc}
              component={site.component}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavTopBar;