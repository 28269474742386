import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NavTopBar from './components/navbar';
import './App.css';

// Lazy load pages for better performance
const Home = lazy(() => import('./pages/home'));
const Projects = lazy(() => import('./pages/projects'));
const Publications = lazy(() => import('./pages/publications'));

export interface Page {
  title: string;
  link: string;
  pageFn: React.FC;
}

interface AppState {
  myName: string;
  pages: Record<string, Page>;
}

const appState: AppState = {
  myName: 'Alexander Speicher',
  pages: {
    HomePage: {
      title: 'Home',
      link: '/',
      pageFn: Home
    },
    ProjectsPage: {
      title: 'Projects',
      link: '/projects',
      pageFn: Projects
    },
    PublicationsPage: {
      title: 'Publications',
      link: '/publications',
      pageFn: Publications
    },
  }
};

const App = () => {
  return (
    <BrowserRouter>
      <NavTopBar myName={appState.myName} pages={appState.pages} />
      <Suspense fallback={<div className="loading">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
