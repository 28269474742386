import React from 'react';

import '../styles/websiteCard.css';

interface WebsiteCardProps {
  title: string;
  link: string;
  imageSrc?: string;
  component?: React.ReactNode;
}

const WebsiteCard = ({ title, link, imageSrc, component }: WebsiteCardProps) => {
  return (
    <a href={link} className="websiteCard" target="_blank" rel="noopener noreferrer">
      {component ? (
        <div className="cardImage">
          {component}
        </div>
      ) : imageSrc ? (
        <img src={imageSrc} alt={title} className="cardImage" />
      ) : null}
      {title && <span className="cardTitle">{title}</span>}
    </a>
  );
};

export default WebsiteCard;